<template>
    <table class="printInfoTbl">
        <thead>
            <tr><th>환자번호</th><th>환자명</th><th>성별</th><th>생년월일</th><th>촬영일</th></tr>
        </thead>
        <tbody>
            <tr>
                <td>{{ patient.patientSn }}</td>
                <td>{{ patient.name }}</td>
                <td>{{ patient.sexStr }}</td>
                <td>{{ patient.birth }}</td>
                <td>{{ pictureDt }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    components: {
    },
    props: {
        patient: {
            type: Object,
            default: () => ({})
        },
        pictureDt: {
            default: ''
        }
    },
    data() {
        return {
            
        };
    },
    watch: {},
    created() { },
    mounted() {
    },
    destroyed() { },
    methods: {
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
</style>
